@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500&display=swap');

.MuiChip-root .MuiChip-avatar {
  width: 56px !important;
  height: 56px !important;
}
.techstack .MuiChip-root .MuiChip-avatar {
  width: 24px !important;
  height: 24px !important;
}
h1 {
  font-family: Montserrat, sans-serif;
}
p {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
}
a {
  color: #fff;
  text-decoration: none;
}
ul{
  list-style-type: decimal;
  padding-left: 0;
}

li {
  list-style-type:decimal;
  padding-bottom: 2px;
}
html,
body {
  margin: 0px 0px 0px 0px;
  height: 100%;
  overflow-x: hidden;
}
#botcardsa {
  position: relative;
  bottom: 20px;
}
#botcardsadesk {
  position: relative;
  bottom: 70px;
}
.pdbga {
  padding: 15px;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>');
}
.pdbgafaculty {
  padding: 30px 60px 80px 60px;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="10 20 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>');
}
.pdbgaactivities {
  padding: 30px 60px 80px 60px;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="1 18 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>');
}
.pdbgafacultymobile {
  padding: 10px 0px 40px 0px;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="40 20 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>');
}
.pdbgax {
  padding: 15px;
  display: flex;
  flex-direction:row;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>');
}
.acadbody {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  padding-top: 20px;
}
.acadbodymob {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
#carsa {
  box-shadow: 0px 10px 2px gray;
  height: 70vh;
}
#carsamob {
  box-shadow: 0px 10px 5px gray;
  height: 50vh;
}
#qlinksa {
  background-color: #26a69a;
  color: #fffff0;
}
#newsindex {
  padding-top: 20px;
}
#lmain {
  margin-right: 2%;
  width: 68%;
}
#rmain {
  margin-left: 2%;
  width: 28%
}
#mobdepro {
  margin-top: 10px;
  padding-bottom: 10px;
}
#mv {
  margin-top: 20px;
  margin-left: 64px;
  margin-right: 64px;
}
div.ql-editor {
  padding:"20px 20px";
  align-items: center;
  text-align: center;
}

div.ql-editor img{
  height: 200px;
}


div.ql-editor table {
    width: 100%;
    border-collapse: collapse;
}

div.ql-editor li {
  list-style-type: square;
}
div.fac th,
div.fac td {
  padding-top: 5px;
  padding-bottom: 5px;
}
#bg {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.maincontainer {
  max-width: 80%;
  margin: 6rem auto;
}
.maincontainermobile {
  background-color: #ffffff;
  margin: 0;
  padding-top: 80px;
  min-height: 125vh;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  scroll-behavior: auto;
}
.maincontainermobile1 {
  background-color: #ffffff;
  margin: 0;
  padding-top: 80px;
  min-height: 135vh;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  scroll-behavior: auto;
}

.imageplaceholder {
  top: 15vh;
  height: 100vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  left: 5%;
  display: inline-block;
  vertical-align: middle;
  z-index: 1;
}
.contentplaceholder {
  background-color: #ffffff;
  width: 60%;
  min-height: 750px;
  position: relative;
  left: 38%; 
  top: -100vh;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: -90vh;
}
.contentplaceholdermobile {
  background-color: #ffffff;
  min-height: 750px;
}
.triangle1 {
  width: 0;
  height: 0;
  position: relative;
  border-top: 100px solid #bffcf8;
  border-left: 100px solid transparent;
  transform: scale(15) translate(20px, 30px) rotate(700deg);
}
.cardcontents {
  position: relative;
  top: -40px;
  color: #062a51;
}
div.labs li {
  padding-top: 10px;
  /* list-style: none; */
}
div.labs {
  text-align: left;
  padding: 15px;
  font-size: 1.1rem;
}
div.labs a {
  text-decoration: none;
  transition: all 0.5s ease-out;
  color: #062a51;
  border-radius: 2px;
  padding: 5px;
}
div.labs a:link {
  text-decoration: none;
}
div.labs a:hover {
  text-decoration: underline;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #062a51;
  color: #75e7e2;
}
.workshopdetails {
  margin-right: 10px;
  float: right;
  display: block;
  border-left: 4px solid #06294f;
  padding-left: 60px;
}
.workshopdetailsmobile {
  margin-right: 10px;
  display: block;
  padding-bottom: 20px;
}
.imageplaceholderspecific {
  background-color: #31c2bb;
  width: 90%;
  height: 45%;
  position: relative;
  top: 15vh;
  left: 5%;
  display: inline-block;
  vertical-align: middle;
  z-index: 1;
}
.contentplaceholderspecific {
  background-color: #ffffff;
  width: 84%;
  min-height: 750px;
  position: relative;
  left: 15%;
  top: -85vh;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
}

.testimonialheading {
  z-index: 1000;
  position: relative;
  top: -35%;
}
.triangle2 {
  width: 0;
  height: 0;
  position: relative;
  border-top: 100px solid rgb(255, 255, 255);
  border-left: 100px solid transparent;
  transform: scale(15) translate(-32.4px, 30px) rotate(680deg);
  z-index: 0;
}
.imgtxt{
  line-height: 100%;
  position: relative;
  font-size: 90%; 
}

.marquee{
  display: inline-block;
  animation: marquee 20s linear infinite;

}

@keyframes marquee{
  0% {transform: translate(0%,50%);}
  100% {transform: translate(0,-100%);}
}
.marquee :hover{
  animation-play-state: paused;
  
}


@media (max-width: 767px) {
  .maincontainer {
    max-width: 100%;
    margin: 3rem 1rem;
  }
}




